<template>
    <Header :isShow="isShow" />
    <div class="wraper">
        <!-- 简介 -->
        <div class="introduction_box" ref="header">
            <div class="Introduction max_small_box" v-if="detail">
                <div class="img" v-if="detail.image != ''">
                    <img :src="imgUrl + detail.image" />
                </div>
                <div class="content">
                    <p v-for="(item,index) in detail.name" :key="index" class="title">{{item}}</p>
                    <div class="desc" v-html="detail.intro"></div>
                </div>
            </div>
        </div>
        <!-- 详情 -->
        <div v-if="detail">
            <div v-if="detail.content[0]" class="line max_big_box"></div>
        </div>
        <div class="detail_box" v-if="detail">
            <div class="detail max_small_box">
                <div class="list" v-for="(item, index) in detail.content" :key="index">
                    <p class="title">{{item.title}}</p>
                    <div class="desc" v-html="item.desc"></div>
                    <div class="imgs" v-if="item.images[0] != ''">
                        <div class="img"  v-for="(list, index) in item.images" :key="index" >
                            <img v-if="list != '' || list != null" :src="imgUrl + list" />
                        </div>
                        
                    </div>
                </div> 
            </div>
        </div>
        <!-- 联系地址 -->
        <div v-if="detail">
            <div class="address" v-if="detail.address != ''">
                <p >联系地址：{{detail.address}}</p>
            </div>
        </div>
        
    </div>
</template>

<script>
import { onBeforeMount, onMounted, onUnmounted, ref, reactive } from 'vue'
import { useRoute } from 'vue-router'
import { get, post } from '../../utils/request';
import * as api from '../../utils/api';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import FloatingFrame from '../../components/floatingFrame/FloatingFrame';
import AboutTop from '../../components/about/AboutTop';

//页面滚动处理函数
const ScrollEffect = () => {

    const isShow = ref('')

    const handleScroll = (height) => {
        
        if(document.documentElement.scrollTop >= height){
            isShow.value = 1
        }else{
            isShow.value = 2
        }
    }

    return { handleScroll, isShow }
}

//详情处理函数
const detailEffect = () => {
    //详情
    const detail = ref()

    //获取详情
    const getDetail = async (id, page) => {
        // console.log(page,'national_inspection','provincial_station')
        let postData = {
            id
        }
        await post('/about/get_about_detail', postData).then((res) => {
            // console.log('简介详情', res)
            if(res.code == 1) {
                res.data.name = res.data.name.split(' ')
                // // console.log(res)
                detail.value = res.data
            }
        })
    }

    return { detail, getDetail }
}

export default {
    name: 'NationalInspectionDetail',
    components: { Header, Footer, FloatingFrame, AboutTop },
    setup() {
        //页面滚动处理函数
        const { 
            handleScroll, 
            isShow 
        } = ScrollEffect()

        //详情处理函数
        const { detail, getDetail } = detailEffect()

        //获取滑块到顶部的距离
        const header = ref(null)

        onMounted(() => {
            const  route = useRoute()
            document.documentElement.scrollTop = document.body.scrollTop =0;
            window.addEventListener('scroll', function () {
                if(header.value){
                    handleScroll(header.value.offsetTop);
                }
            })
            getDetail(route.params.id, route.params.page)
        });

        const { imgUrl }  = api

        return { imgUrl, isShow, header, detail }
    }
}
</script>

<style lang="scss" scoped>
/* 简介 */
.introduction_box{
    margin-top: 20px;
}
.Introduction{
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 1200px){
        padding: 0 30px;
    }
    @media screen and (max-width: 900px){
        display: block;
    }
    .img{
        width: 30%;
        /* height: 250px; */
        height: 180px;
        @media screen and (max-width: 900px){
            width: 100%;
            height: auto;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .content{
        width: 65%;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        justify-content: flex-start;
        @media screen and (max-width: 900px){
            width: 100%;
            margin-top: 15px;
        }
        .title{
            color: rgba(47, 80, 158, 100);
            /* font-size: 36px; */
            font-size: 28px;
            font-weight: 600;
            line-height: 50px;
            @media screen and (max-width: 900px){
                font-size: .2rem;
            }
        }
        .desc{
            color: rgba(16, 16, 16, 100);
            font-size: 16px;
            line-height: 30px;
            margin-top: 25px;
            @media screen and (max-width: 900px){
                font-size: .16rem;
            }
        }
    }
}
.line{
    height: 2px;
    background-color: #bbb;
    margin-top: 40px;
}
/* 详情 */
/* .detail_box{
    margin-top: 40px;
    @media screen and (max-width: 1200px){
        padding: 0 30px;
    }
}
.detail{
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 900px){
        display: block;
    }
    .list{
        width: 48%;
        margin-right: 4%;
        margin-bottom: 50px;
        &:nth-child(2n){
            margin-right: 0%;
        }
        @media screen and (max-width: 900px){
            width: 100%;
            margin-right: 0%;
        }
        .title{
            color: rgba(47, 80, 158, 100);
            font-size: 16px;
            font-weight: 600;
            @media screen and (max-width: 900px){
                font-size: .16rem;
            }
        }
        .desc{
            color: rgba(16, 16, 16, 100);
            font-size: 14px;
            margin-top: 14px;
            line-height: 24px;
            @media screen and (max-width: 900px){
                font-size: .14rem;
            }
        }
        .imgs{
            display: flex;
            flex-wrap: wrap;
            margin-top: 40px;
            .img{
                width: 21%;
                object-fit: cover;
                margin-right: 5%;
                margin-bottom: 15px;
                &:nth-child(4n){
                    margin-right: 0%;
                }
                img{
                    width: 100%;
                }
            }
        }
    }
} */
/* 详情 */
.detail_title{
    color: #305aa8;
    font-size: 20px;
    font-weight: bold;
    margin-top: 30px;
    padding-bottom: 20px;
    border-bottom: 2px solid #e6e6e6;
    @media screen and (max-width: 1200px){
        padding-left: 30px;
    }
    /* border-bottom: 1px solid linear-gradient(to bottom right, red, yellow); */
    /* background-image: linear-gradient(to bottom right, red, yellow); */
}
.detail_box{
    margin-top: 40px;
    @media screen and (max-width: 1200px){
        padding: 0 30px;
    }
}
.detail{
    /* display: flex; */
    flex-wrap: wrap;
    @media screen and (max-width: 900px){
        display: block;
    }
    .list{
        width: 80%;
        margin-right: 4%;
        margin-bottom: 50px;
        overflow-x: auto;
        &:nth-child(2n){
            margin-right: 0%;
        }
        @media screen and (max-width: 900px){
            width: 100%;
            margin-right: 0%;
        }
        .title{
            color: rgba(47, 80, 158, 100);
            font-size: 18px;
            font-weight: 600;
            /* &:after{
                content: "  ";
                width: 100%;
                height: 1px;
                display: block;
                background-color: #e2e2e2;
            } */
            @media screen and (max-width: 900px){
                font-size: .16rem;
            }
        }
        .desc{
            color: rgba(16, 16, 16, 100);
            font-size: 16px;
            margin-top: 14px;
            line-height: 24px;
            @media screen and (max-width: 900px){
                font-size: .14rem;
            }
        }
        .imgs{
            display: flex;
            flex-wrap: wrap;
            margin-top: 40px;
            .img{
                width: 21%;
                object-fit: cover;
                margin-right: 5%;
                margin-bottom: 15px;
                &:nth-child(4n){
                    margin-right: 0%;
                }
                img{
                    width: 100%;
                }
            }
        }
    }
}
/* 联系地址 */
.address{
    background-color: #F4F4F4;
    padding: 30px 0;
    p{
        color: rgba(16, 16, 16, 100);
        font-size: 14px;
        text-align: center;
        margin-bottom: 20px;
        &:last-child{
            margin-bottom: 0px;
        }
    }
}
</style>